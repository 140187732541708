<template>
  <ui-view-card :title="$t('signature.title')">
    <div class="row">
      <div class="card col-sm-6 p-0">
        <template v-if="form?.organiserSignature?.signingDate">
          <ui-view-card :title="$t('signature.organiser')" :type="'col-lg-12'">
            {{ organiserName }}
            <br />
            {{ form.organiserSignature.email }}
            <br />
            {{ form.organiserSignature.organisation }}
            <br />
            {{ formatDate(form.organiserSignature.signingDate) }}
            <br />
            <br />
            <button type="button" class="btn btn-outline-secondary" @click="validateSignature()"><i class="bi-check" />&nbsp;{{$t('signature.validate_signature')}}</button>
          </ui-view-card>
        </template>
        <template v-else-if="$roles([roles.ORGANISATION_ADMIN, roles.USER, roles.DRIVER_BOSSCHAERT])">
          <button id="signOrganiserBtn" type="button" class="btn btn-primary" @click="showSign()"><i class="bi-pencil-square" />&nbsp;{{$t('signature.sign_as_organiser')}}</button>
        </template>
        <template v-else>
          <h4 class="text-center">
            {{$t('signature.organiser_not_signed')}}
          </h4>
        </template>
      </div>
      <div class="card col-sm-6 p-0">
        <template v-if="form?.processorSignature?.signingDate">
          <ui-view-card id="processorSignature" :title="$t('signature.processor')" :type="'col-lg-12'">
            {{ processorName }}
            <br />
            {{ form.processorSignature.email }}
            <br />
            {{ form.processorSignature.organisation }}
            <br />
            {{ formatDate(form.processorSignature.signingDate) }}
          </ui-view-card>
        </template>
        <template v-else-if="processorInfo">
          <button id="signProcessorBtn" type="button" class="btn btn-primary" @click="signProcessor" :disabled="locating || signing"><i class="bi-pencil-square" />&nbsp;{{$t('signature.sign_for_delivery')}}</button>
        </template>
        <template v-else>
          <h4 class="text-center">
            {{$t('signature.processor_not_signed')}}
          </h4>
        </template>
      </div>
    </div>
  </ui-view-card>
  <form-sign-modal ref="signModal" :callback="sign" :form="form"/>
</template>

<script>

import UiViewCard from '@/components/ui-view-card'
import {computed, ref} from 'vue'
import backend from '@/util/backend'
import FormSignModal from '@/views/forms/shared/Sign-Modal'
import Notifier from '@/util/notifier'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import WasteIdentificationModel from '@/models/WasteIdentificationModel'

export default {
  name: 'ui-view-card-signature',
  components: {
    FormSignModal,
    UiViewCard,
  },
  props: {
    form: {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:    Object,
      default: null,
    },
    uuid: {
      type:    String,
      default: '',
    },
    sharelink: {
      type:     Boolean,
      required: true,
    }
  },
  emits: [ 'signed' ],
  setup: (props, { emit }) => {
    const signModal = ref('signModal')
    const notifier = Notifier()
    const showSign = () => {
      const err = WasteIdentificationModel.formCheck(props.form, true)
      if (err) {
        notifier.error(err)
        return false
      }
      signModal.value.modal.open()
    }
    const sign = () => {
      backend.put(`api/documents/${props.form.id}/sign`).then(() => {
        session.setOrganisation()
        notifier.success('toast.form_signed')
        emit('signed')
      }).catch(() => {
        notifier.error('toast.form_sign_failed')
      }).finally(() => {
        signModal.value.modal.close()
      })
    }

    const locating = ref(false)
    const signing = ref(false)
    if (props.processorInfo) {
      geolocation.watchPosition()
    }
    const signProcessor = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          signProcessor()
        }, 3000)
      } else {
        locating.value = false
        notifier.success('toast.end_location_saved')
        signing.value = true
        const signingUrl = props.sharelink ? `api/sharedlinks/${props.uuid}/stop` : `api/documents/${props.form.id}/stop`
        backend.put(signingUrl, {
          'coordinates': geolocation.getLocation(),
          'firstName': props.processorInfo.firstName,
          'lastName': props.processorInfo.lastName,
          'organisation': props.processorInfo.organisation,
        }).then(() => {
          notifier.success('toast.form_signed')
          emit('signed')
        }).catch(() => {
          notifier.error('toast.form_sign_failed')
        }).finally(() => {
          signing.value = false
        })
      }
    }

    const dateOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    }

    const formatDate = (date) => {
      const d = new Date(date)
      return new Intl.DateTimeFormat('nl', dateOptions).format(d)
    }

    const organiserName = computed(() => {
      return props.form.organiserSignature ? props.form.organiserSignature.firstName + ' ' + props.form.organiserSignature.lastName : ''
    })

    const processorName = computed(() => {
      return props.form.processorSignature ? props.form.processorSignature.firstName + ' ' + props.form.processorSignature.lastName : ''
    })

    const validateSignature = () => {
      const url = location.origin+`/api/documents/${props.form.id}/verifySignature?signatureHash=${props.form.organiserSignature.hash}`
      window.open(url, '_blank')
    }

    return {
      signModal,
      showSign,
      sign,
      signProcessor,
      organiserName,
      processorName,
      formatDate,
      validateSignature,
      locating,
      signing,
    }
  }
}
</script>
