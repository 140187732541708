<template>
  <layout>
    <template #header>
      <ui-header :title="$t('waste_identification.list.waste_identification')">
        <template #actions v-if="currentTab === TABS.FORMS">
          <div v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER_BOSSCHAERT])" class="btn-group">
            <button id="actionsDropdown" :disabled="selection.length === 0"
                    class="btn btn-outline-secondary dropdown-toggle"
                    data-bs-toggle="dropdown" type="button">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="waste-identification-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])" id="shareBtn"
                  :title="notShareableSelected ? $t('waste_identification.view.only_signed_shared') : ''">
                <button :disabled="selection.length === 0 || notShareableSelected" class="dropdown-item"
                        @click="showShare()">
                  {{ $t('waste_identification.list.actions.share_with_driver') }}
                </button>
              </li>
              <li v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER_BOSSCHAERT])"
                  :title="draftSelected ? $t('waste_identification.view.draft_no_export') : ''">
                <button :disabled="selection.length === 0 || draftSelected" class="dropdown-item"
                        @click="showExport()">
                  {{ $t('waste_identification.list.actions.export') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])">
                <button :disabled="selection.length === 0" class="dropdown-item" @click="showDuplicate()">
                  {{ $t('waste_identification.list.actions.duplicate') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])">
                <button :disabled="selection.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('waste_identification.list.actions.delete') }}
                </button>
              </li>
              <li v-if="$flag('empty_container') && $roles([roles.USER, roles.DRIVER_BOSSCHAERT])">
                <button :disabled="selection.length === 0" class="dropdown-item" @click="showSetStateEmpty()">
                  {{ $t('waste_identification.list.actions.set_state_empty') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])"
                  :title="notFinishableSelected ? $t('waste_identification.view.only_arrived_finish') : ''">
                <button :disabled="selection.length === 0 || notFinishableSelected" class="dropdown-item"
                        @click="showFinish()">
                  {{ $t('waste_identification.list.actions.finish') }}
                </button>
              </li>
            </ul>
          </div>
          <button v-if="$roles([roles.USER, roles.DRIVER_BOSSCHAERT])" class="btn btn-outline-primary"
                  data-cy="create-form" type="button"
                  @click="addForm()"><i class="bi-plus"/>&nbsp;{{ $t('general.add_form') }}
          </button>
        </template>
        <template #actions v-else-if="currentTab === TABS.TEMPLATES">
          <waste-identification-list-user-templates-actions :selection="selectedTemplates" @reload="reloadTemplates()"/>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
        <ui-list-tabs v-if="$flag('SJABLONEN') && $roles([roles.USER])" v-model="currentTab"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary"
                @click="currentTab === TABS.FORMS ? resetGrid() : templates.resetGrid()">{{
            $t('general.reset_filters')
          }}
        </button>
      </div>
    </div>
    <ui-content>
      <div class="tab-content h-100">
        <div
            id="forms"
            :class="{
            'show': currentTab === TABS.FORMS,
            'active': currentTab === TABS.FORMS,
          }"
            class="tab-pane fade h-100"
            role="tabpanel"
        >
          <div class="d-flex flex-column h-100">
            <ag-grid-vue
                :serverSideDatasource="grid.datasource('api/documents/list?documentType=WASTE_IDENTIFICATION')"
                :grid-options="grid.defaultOptions"
                :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
                class="ag-theme-quartz"
                style="min-height:100%"
                @row-clicked="rowClick"
                @selection-changed="refreshSelection"
                @grid-ready="onGridReady"
                @grid-pre-destroyed="onGridPreDestroyed"
                @filter-changed="onFilterChanged"
                @first-data-rendered="onFirstDataRendered"
                :initialState="initialState"
            />
          </div>
        </div>
        <div
            id="templates"
            :class="{
            'show': currentTab === TABS.TEMPLATES,
            'active': currentTab === TABS.TEMPLATES,
          }"
            class="tab-pane fade h-100"
            role="tabpanel"
        >
          <waste-identification-list-user-templates ref="templates"
                                                    v-model:selection="selectedTemplates"
                                                    :active="currentTab === TABS.TEMPLATES"/>
        </div>
      </div>
    </ui-content>
  </layout>
  <form-duplicate-modal ref="duplicateModal" :amount="selection.length" :callback="duplicate"/>
  <form-delete-modal ref="removeModal" :amount="countSelectedDrafts" :callback="remove"/>
  <form-share-modal :id="selection.length === 1 ? selection[0].id : null" ref="shareModal" :amount="selection.length"
                    :callback="share"
                    :document-type="DocumentType.WASTE_IDENTIFICATION"
                    :multiple="selection.length > 1" @reload="reload"
  />
  <form-export-modal ref="exportModal" :amount="selection.length" :callback="exportForms"/>
  <form-finish-modal ref="finishModal" :amount="selection.length" :callback="finishForms" :demo="demo"/>
  <form-set-state-empty-modal ref="setStateEmptyModal" :amount="selection.length" :callback="setStateEmpty"/>
</template>

<script>

import {computed, inject, onBeforeUnmount, ref, watch} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRoute, useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import FormShareModal from '@/views/forms/shared/Share-Modal'
import FormExportModal from '@/views/forms/shared/Export-Modal'
import FormSetStateEmptyModal from '@/views/forms/shared/Set-State-Empty-Modal'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import stateColorRenderer from '@/util/stateColorRenderer'
import checkboxRenderer from '@/util/checkboxRenderer'
import WasteIdentificationModel from '@/models/WasteIdentificationModel'
import grid from '@/util/grid'
import UiListTabs from '@/components/ui-list-tabs'
import TABS from '@/types/tabs'
import WasteIdentificationListUserTemplates from '@/views/forms/waste-identification/List-User-Templates'
import TransportDocumentTemplateModel from '@/models/TransportDocumentTemplateModel'
import WasteIdentificationListUserTemplatesActions from '@/views/forms/waste-identification/List-User-Templates-Actions'
import ExportType from '@/types/exportType'
import session from '@/util/session'
import FormFinishModal from '@/views/forms/shared/Finish-Modal.vue'
import OrganisationState from '@/types/organisationState'
import WeightEditedIcon from '@/components/weight-edited-icon.vue'

export default {
  name:       'WasteIdentificationListUser',
  computed:   {
    TABS() {
      return TABS
    },
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    FormFinishModal,
    WasteIdentificationListUserTemplatesActions,
    WasteIdentificationListUserTemplates,
    UiListTabs,
    FormSetStateEmptyModal,
    FormExportModal,
    FormShareModal,
    FormDeleteModal,
    FormDuplicateModal,
    UiContent,
    UiHeader,
    Layout,
    UiBreadcrumbs,
    AgGridVue,
    // eslint-disable-next-line
    stateColorRenderer,
    // eslint-disable-next-line
    checkboxRenderer,
    // eslint-disable-next-line
    WeightEditedIcon,
  },
  setup:      () => {
    const breadcrumbs       = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION]
    const $t                = inject('$t')
    const $isSafari         = inject('$isSafari')
    const notifier          = Notifier()
    const route             = useRoute()
    const currentTab        = ref(route?.query?.tab ?? TABS.FORMS)
    const selectedTemplates = ref([]) // for some reason emit update:selection causes rerender

    const xsWindow     = ref(window.innerWidth < 576)
    const xsColumnDefs = [
      {
        headerName:  $t('waste_identification.list.table.key'),
        valueGetter: (params) => {
          let res = 'DGF-' + params.data.id
          if (params.data.demo) {
            res += ' (demo)'
          }
          return res
        },
        field:       'id',
      },
      {
        headerName:   $t('waste_identification.list.table.state'),
        field:        'state',
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    const columnDefs = [
      {
        headerName:  $t('waste_identification.list.table.key'),
        field:       'id',
        maxWidth:    250,
        valueGetter: (params) => {
          let res = 'DGF-' + params.data.id
          if (params.data.demo) {
            res += ' (demo)'
          }
          return res
        },
      },
      {
        headerName:     $t('waste_identification.list.table.date'),
        field:          'transportDate',
        maxWidth:       250,
        filter:         'agDateColumnFilter',
        filterParams:   {
          minValidYear: 2000,
          maxValidYear: 2099,
          // defaultOption: DateFormatter.formatDate(new Date()),
          filterOptions: ['inRange'],
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {headerName: $t('waste_identification.list.table.waste_description'), field: 'wasteDescription'},
      {
        headerName:     $t('waste_identification.list.table.amount'),
        field:          'amount',
        maxWidth:       200,
        cellRenderer:   (params) => {
          let result = ''
          if (params.data.amount) {
            result += params.data.amount + ' ' + $t('form.table.tonne')
          }
          if (params.data.amountChanged) {
            const weightEdited = $t('form.weight_edited')
            result
                += ' <span class="fs-4" v-if="weightEdited" title="' + weightEdited + '">' +
                '<i class="bi-pencil" style="font-size: 14px; color:green"/>' +
                '</span>'
          }
          return result
        },
        valueFormatter: (params) => {
          if (params.value) {

            return params.value + ' ' + $t('form.table.tonne')
          } else {
            return ''
          }
        },
      },
      {headerName: $t('waste_identification.list.table.from'), field: 'from'},
      {headerName: $t('waste_identification.list.table.to'), field: 'to'},
      {headerName: $t('waste_identification.list.table.transporter'), field: 'transporter'},
      {
        headerName:         $t('waste_identification.list.table.dangerous'),
        field:              'dangerous',
        maxWidth:           200,
        suppressSizeToFit:  true,
        width:              100,
        filter:             'agSetColumnFilter',
        filterParams:       {
          values:         ['WASTE_TRANSPORT_DANGEROUS', 'WASTE_TRANSPORT_NON_DANGEROUS'],
          valueFormatter: (val) => {
            return $t('form.type.' + val.value)
          },
        },
        cellRenderer:       'checkboxRenderer',
        cellRendererParams: {
          callback: (data) => {
            return data.dangerous
          },
        },
      },
      {
        headerName:   $t('waste_identification.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    let active = computed(() => {
      return currentTab.value === TABS.FORMS
    })
    let sized  = false
    watch(() => active.value, (val) => {
      if (val && !sized) {
        resize()
        sized = true
      }
    })

    const resize = () => {
      if (active.value) {
        xsWindow.value = window.innerWidth < 576
        setTimeout(() => {
          api.sizeColumnsToFit({'defaultMinWidth': 200})
        }, 1)
      } else {
        sized = false
      }
    }
    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
    })

    const initialFilter = {
      'state': {
        filterType: 'set',
        values:     FormState.getAll(false),
      },
    }
    const gridKey       = 'waste-identification-list-user'
    let formState       = session.loadFormState(gridKey)
    if (formState === null) {
      formState = {filter: {filterModel: initialFilter}}
    }
    const initialState       = ref(formState)
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged    = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api                   = null
    const onGridReady         = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
      if (active.value) {
        sized = true
      }
    }
    const onFirstDataRendered = () => {
      api?.sizeColumnsToFit({'defaultMinWidth': 100})
    }
    onBeforeUnmount(() => {
      api = null
    })
    const reload    = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }
    const resetGrid = () => {
      api?.setFilterModel(initialFilter)
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const router   = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/waste-identification/view/${event.data.id}`)
      }
    }

    const addForm = async () => {
      const result = await backend.post('api/documents', JSON.parse(JSON.stringify(WasteIdentificationModel.empty)))
      if (result.status === 200) {
        router.push(`/waste-identification/edit/${result.data.id}`)
      } else {
        notifier.error('toast.form_create_failed')
      }
    }

    const addTemplate = async () => {
      const result = await backend.post('api/transportDocumentTemplates', JSON.parse(JSON.stringify(TransportDocumentTemplateModel.wasteIdentification)))
      if (result.status === 200) {
        router.push(`/transport-document-templates/edit/${result.data.id}`)
      } else {
        notifier.error('toast.template_create_failed')
      }
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate  = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate      = () => {
      const ids = api.getSelectedRows().map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'COPY',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.duplication_successful')
          } else {
            notifier.error('toast.duplication_failed')
          }
        }).finally(() => {
          duplicateModal.value.modal.close()
          reload()
        })
      }
    }

    const shareModal = ref('shareModal')
    const showShare  = () => {
      shareModal.value.modal.open()
    }
    const share      = async (shareWith) => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state !== 'DRAFT'
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        await backend.post('api/documents/batch', {
          'action': 'SHARE_DRIVER',
          'ids':    ids,
          'params': shareWith,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.sharing_successful')
          } else {
            notifier.error('toast.sharing_failed')
          }
        }).finally(() => {
          shareModal.value.modal.close()
          reload()
        })
      }
    }

    const exportModal = ref('exportModal')
    const showExport  = () => {
      exportModal.value.modal.open()
    }
    const exportForms = (language, type) => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state !== 'DRAFT'
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        switch (type) {
          case ExportType.PDF: {
            if ($isSafari()) {
              const route = router.resolve(`/export-pdf/${language}/${ids.join(',')}`)
              window.open(route.href, '_blank')
            } else {
              exportPdf(ids, language)
            }
            return
          }
          case ExportType.JSON: {
            if ($isSafari()) {
              const route = router.resolve(`/export-json/${ids.join(',')}`)
              window.open(route.href, '_blank')
            } else {
              exportJson(ids)
            }
            return
          }
          case ExportType.CSV: {
            if ($isSafari()) {
              const route = router.resolve(`/export-csv/${ids.join(',')}`)
              window.open(route.href, '_blank')
            } else {
              exportCsv(ids)
            }
            return
          }
        }
      }
    }

    const exportPdf = (ids, language) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_PDF',
        'ids':    ids,
        'params': {
          'language': language,
        },
      }, {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/pdf'})
          const url  = URL.createObjectURL(blob)
          window.open(url, '_blank')
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportJson = (ids) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_JSON',
        'ids':    ids,
      }).then((result) => {
        if (result.status === 200) {
          const data = JSON.stringify(result.data[0])
          const blob = new Blob([data], {type: 'application/json'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportCsv = (ids) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_CSV',
        'ids':    ids,
      }).then((result) => {
        if (result.status === 200) {
          const blob      = new Blob([result.data])
          const url       = URL.createObjectURL(blob)
          const a         = document.createElement('a')
          a.style.display = 'none'
          a.href          = url
          a.download      = `export.csv`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const removeModal = ref('removeModal')
    const showRemove  = () => {
      removeModal.value.modal.open()
    }
    const remove      = () => {
      const ids = api.getSelectedRows().filter((row) => {
        return FormState.canDelete(row.state)
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'DELETE',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.deleting_successful')
          } else {
            notifier.error('toast.deleting_failed')
          }
        }).finally(() => {
          removeModal.value.modal.close()
          reload()
        })
      } else {
        removeModal.value.modal.close()
      }
    }

    const setStateEmptyModal = ref('setStateEmptyModal')
    const showSetStateEmpty  = () => {
      setStateEmptyModal.value.modal.open()
    }
    const setStateEmpty      = () => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state === FormState.DRAFT
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'EMPTY',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.update_successful')
          } else {
            notifier.error('toast.update_failed')
          }
        }).finally(() => {
          setStateEmptyModal.value.modal.close()
          reload()
        })
      }
    }

    const finishModal = ref('finishModal')
    const showFinish  = () => {
      checkDemo()
      finishModal.value.modal.open()
    }
    const finishForms = async () => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state === FormState.ARRIVED
      }).map((row) => {
        return row.id
      })
      if (ids.length === 1) {
        const result = await backend.post('api/documents/batch', {
          'action': 'FINISH',
          'ids':    ids,
        })
        if (result.status === 200) {
          notifier.success('toast.finish_successful')
        } else {
          notifier.error('toast.finish_failed')
        }
        finishModal.value.modal.close()
        reload()
      } else if (ids.length > 1) {
        backend.post('api/documents/batch', {
          'action': 'FINISH',
          'ids':    ids,
        })
        notifier.notify('toast.finish_ongoing')
        finishModal.value.modal.close()
      } else {
        finishModal.value.modal.close()
        reload()
      }
    }

    const draftSelected         = ref(false)
    const notShareableSelected  = ref(false)
    const notFinishableSelected = ref(false)
    const countSelectedDrafts   = ref(0)

    const selection        = ref([])
    const refreshSelection = (params) => {
      selection.value             = params.api.getSelectedRows()
      draftSelected.value         = selection.value.filter((item) => {
        return FormState.isDraft(item.state)
      }).length > 0
      countSelectedDrafts.value   = selection.value.filter((item) => {
        return FormState.isDraft(item.state)
      }).length
      notShareableSelected.value  = selection.value.filter((item) => {
        return !FormState.canShare(item.state)
      }).length > 0
      notFinishableSelected.value = selection.value.filter((item) => {
        return !FormState.canFinish(item.state)
      }).length > 0
    }

    const templates       = ref('templates')
    const reloadTemplates = () => {
      templates.value.reload()
    }

    const org       = session.getOrganisation()
    const demo      = ref(false)
    const checkDemo = () => {
      demo.value =
          (org && org.organisationState === OrganisationState.ONBOARDING) ||
          (api?.getSelectedRows().filter((row) => {
            return row.demo
          }).length > 0)
    }

    return {
      breadcrumbs,
      reload,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      rowClick,
      onGridReady,
      onFirstDataRendered,
      onGridPreDestroyed,
      onFilterChanged,
      initialState,
      addForm,
      addTemplate,
      refreshSelection,
      notShareableSelected,
      notFinishableSelected,
      draftSelected,
      countSelectedDrafts,
      selection,
      duplicateModal,
      showDuplicate,
      duplicate,
      removeModal,
      showRemove,
      remove,
      shareModal,
      showShare,
      share,
      exportModal,
      showExport,
      exportForms,
      setStateEmptyModal,
      showSetStateEmpty,
      setStateEmpty,
      finishModal,
      showFinish,
      finishForms,
      currentTab,
      selectedTemplates,
      templates,
      reloadTemplates,
      resetGrid,
      resize,
      demo,
    }
  },
}
</script>
