<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : $t('grondbank.view.title', {id: link.documentId})"
                 :back-function="step === steps.arrive ? backToViewing : null">
        <template #actions>
          <template v-if="!loading && step === steps.viewing">
            <div class="btn-group" v-if="attachmentsFlag">
              <button type="button" class="btn btn-outline-secondary dropdown-toggle" id="viewingActions"
                      data-bs-toggle="dropdown" aria-expanded="false">
                {{ $t('general.actions') }}
              </button>
              <ul class="dropdown-menu dropdown-menu-end text-large" aria-labelledby="viewingActions">
                <li v-if="attachmentsFlag">
                  <button id="attachmentsBtn" type="button" class="dropdown-item btn btn-outline-secondary"
                          @click="showAttachments()">{{ $t('grondbank.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <button
                v-if="!rideInProgress && !FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-primary"
                @click="showStart()"><i class="bi-truck"/>&nbsp;{{ $t('forms.view.start_ride') }}
            </button>
            <button
                v-if="rideInProgress && !FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-primary"
                @click="showStop()"><i class="bi-slash-circle"/>&nbsp;{{ $t('forms.view.stop_ride') }}
            </button>
          </template>
        </template>
      </ui-header>
    </template>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-else-if="step === steps.driverinfo">
        <div class="container-fluid">
          <form id="driver-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.driver_information')" :key="driver.language">
              <ui-view-card-language v-model="driver.language" :required="true" :own-language="true"/>
              <template v-if="driver.language">
                <ui-view-card-field data-cy="driver-email" :label="$t('drivers.form.email')" v-model="driver.email"
                                    :required="true" :readonly="true"/>
                <ui-view-card-field data-cy="driver-first-name" :label="$t('drivers.form.first_name')"
                                    v-model="driver.firstName" :required="true"/>
                <ui-view-card-field data-cy="driver-last-name" :label="$t('drivers.form.last_name')"
                                    v-model="driver.lastName" :required="true"/>
                <ui-view-card-field data-cy="driver-organisation" :label="$t('drivers.form.organisation')"
                                    v-model="driver.organisation" :required="true"/>
                <br/>
                <br/>
                <div class="row g-0 justify-content-center">
                  <button id="saveDriverBtn" type="button" class="btn" :class="'btn-outline-primary'"
                          @click="saveDriver()" form="driver-form" :disabled="driverSaving"><i
                      class="bi-save"/>&nbsp;{{ $t('general.confirm') }}
                  </button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else-if="step === steps.viewing">
        <grondbank-form-view :form="form" :uuid="link.id" @ride-added="loadForm()"
                             @signed="loadForm()" :key="form.lastUpdate"/>
      </template>
    </ui-content>
  </layout>
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :sharelink="true" :uuid="link.id"
                          :callback="saveAttachments" :attachments="form.attachments"/>
  <start-ride-modal :callback="startRide" ref="startRideModal"/>
  <stop-ride-modal :callback="stopRide" ref="stopRideModal"/>
</template>

<script>

import UiContent from '@/components/ui-content'
import {computed, inject, provide, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import Notifier from '@/util/notifier'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import Spinner from '@/components/spinner'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import FormState from '@/types/formState'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal'
import GrondbankFormView from '@/partials/grondbank/FormView'
import GrondbankModel from '@/models/GrondbankModel'
import StopRideModal from '@/views/forms/grondbank/Stop-Ride-Modal.vue'
import StartRideModal from '@/views/forms/grondbank/Start-Ride-Modal.vue'

export default {
  name:       'SharedLinkDriverGrondbank',
  computed:   {
    FormState() {
      return FormState
    },
  },
  components: {
    StartRideModal,
    StopRideModal,
    GrondbankFormView,
    FormAttachmentsModal,
    UiViewCardLanguage,
    Spinner,
    UiHeader,
    Layout,
    UiViewCardField,
    UiViewCard,
    UiContent,
  },
  props:      {
    link: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $tset    = inject('$tset')
    const notifier = Notifier()

    const steps = {
      driverinfo: 'driverinfo',
      viewing:    'viewing',
      signing:    'signing',
    }
    const step  = ref(steps.driverinfo)

    $tset(props.link.language)
    const driver = ref({
      email:        props.link.email,
      firstName:    props.link.firstName,
      lastName:     props.link.lastName,
      organisation: props.link.organisation,
      language:     props.link.language,
    })
    watch(() => driver.value.language, (l) => {
      $tset(l)
    })
    provide('driverInfo', driver)

    const loading      = ref(true)
    const driverSaving = ref(false)
    const saveDriver   = () => {
      driverSaving.value = true
      const driverForm   = $('#driver-form')[0]
      if (!driverForm.checkValidity()) {
        // something in the form is not valid so don't allow signing
        driverForm.reportValidity()
        driverSaving.value = false
        return
      }
      backend.put(`/api/sharedlinks/${props.link.id}/userData`, {
        firstName:    driver.value.firstName,
        lastName:     driver.value.lastName,
        organisation: driver.value.organisation,
        language:     driver.value.language,
      }).then(() => {
        notifier.success('toast.user_confirmed')
        step.value = steps.viewing
      }).finally(() => {
        driverSaving.value = false
      })
    }

    const form     = ref({})
    const loadForm = () => {
      backend.get(`api/sharedlinks/${props.link.id}/document`).then((doc) => {
        form.value    = doc.data
        loading.value = false
      })
    }
    loadForm()

    const saving         = ref(false)
    const saveWeightEdit = async () => {
      saving.value = true
      const result = await backend.put(`api/sharedlinks/${props.link.id}/document/${props.link.documentId}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
        return true
      } else {
        notifier.error('toast.save_failed')
        return false
      }
    }

    const stopEdit = () => {
      loadForm()
      step.value = steps.viewing
    }

    const signerInfo    = ref({})
    const signProcessor = () => {
      step.value       = steps.signing
      signerInfo.value = {
        'language':     form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }

    const signDriver = () => {
      step.value       = steps.signing
      signerInfo.value = {
        'firstName':    driver.value.firstName,
        'lastName':     driver.value.lastName,
        'language':     driver.value.language,
        'organisation': driver.value.organisation,
      }
    }

    const signing               = ref(false)
    // click button at bottom to trigger signing
    const clickSignProcessorBtn = () => {
      signing.value = true
      document.getElementById('signProcessorBtn').click()
      setTimeout(() => {
        signing.value = false
      }, 4000)
    }

    const isProcessorSet = ref(false)

    const backToViewing = () => {
      step.value = steps.viewing
    }

    const signed = () => {
      step.value    = steps.viewing
      signing.value = false
      loadForm()
    }

    const editWeightsModal     = ref('editWeightsModal')
    const showEditWeightsModal = () => {
      editWeightsModal.value.modal.open()
    }

    const config           = inject('config')
    const attachmentsFlag  = ref(config.value.getConfig('ATTACHMENTS'))
    const attachmentsModal = ref('attachmentsModal')
    const showAttachments  = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments  = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    const rideInProgress = computed(() => {
      let res = false
      form.value.soilRides.forEach((ride) => {
        if (ride.startOfTransport && !ride.endOfTransport) {
          res = true
        }
      })
      return res
    })

    const startRideModal = ref('startRideModal')
    const showStart      = () => {
      startRideModal.value.ride                  = JSON.parse(JSON.stringify(GrondbankModel.soilRide))
      startRideModal.value.ride.startOfTransport = new Date()
      startRideModal.value.modal.open()
    }
    const startRide      = async (ride) => {
      const newRide = Object.assign(JSON.parse(JSON.stringify(GrondbankModel.soilRide)), {
        volume:           ride.volume,
        startOfTransport: ride.startOfTransport.getHours().toString().padStart(2, '0') + ':' + ride.startOfTransport.getMinutes().toString().padStart(2, '0'),
      })

      form.value.soilRides.push(newRide)

      const url    = `api/documents/${form.value.id}/rides/start`
      const result = await backend.put(url, newRide)
      if (result.status === 200) {
        loadForm()
      } else {
        notifier.error('toast.save_failed')
      }
      return result
    }

    const stopRideModal = ref('stopRideModal')
    const showStop      = () => {
      stopRideModal.value.ride                = JSON.parse(JSON.stringify(form.value.soilRides.find((r) => {
        return r.startOfTransport && !r.endOfTransport
      })))
      stopRideModal.value.ride.endOfTransport = new Date()
      stopRideModal.value.modal.open()
    }
    const stopRide      = async (ride) => {
      const selectedRide = form.value.soilRides.find((r) => {
        return r.startOfTransport && !r.endOfTransport
      })

      selectedRide.volume = ride.volume
      selectedRide.endOfTransport
                          = ride.endOfTransport.getHours().toString().padStart(2, '0') + ':' + ride.endOfTransport.getMinutes().toString().padStart(2, '0')

      const url    = `api/documents/${form.value.id}/rides/stop`
      const result = await backend.put(url, selectedRide)
      if (result.status === 200) {
        loadForm()
      } else {
        notifier.error('toast.save_failed')
      }
      return result
    }

    return {
      loading,
      step,
      steps,
      driver,
      saveDriver,
      driverSaving,
      form,
      stopEdit,
      saving,
      backToViewing,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      clickSignProcessorBtn,
      isProcessorSet,
      signed,
      saveWeightEdit,
      editWeightsModal,
      showEditWeightsModal,
      attachmentsFlag,
      attachmentsModal,
      saveAttachments,
      showAttachments,
      loadForm,
      rideInProgress,
      showStart,
      startRideModal,
      startRide,
      showStop,
      stopRideModal,
      stopRide,
    }
  },
}
</script>
